.loading-answer {
  display: flex;
  gap: 4px;
}

.loading-answer div {
  width: 6px;
  height: 6px;
  border-radius: 6px;
}

.loading-answer-1 {
  animation: loading-1 1s linear 0s infinite;
}

@keyframes loading-1 {
  25% {
    transform: translateY(-4px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(0px);
  }
}

.loading-answer-2 {
  animation: loading-2 1s linear 0s infinite;
}

@keyframes loading-2 {
  25% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-4px);
  }
  75% {
    transform: translateY(0px);
  }
}

.loading-answer-3 {
  animation: loading-3 1s linear 0s infinite;
}

@keyframes loading-3 {
  25% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-4px);
  }
}