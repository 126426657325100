  .chat {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #fff;
  }

  .chat--dark {
    background: #222;
  }
  
  .chat-header {
    padding: 16px;
    color: white;
    text-align: center;
    flex: 0 0 auto;
  }

  .chat-header p {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    letter-spacing: 1px;
  }
  
  .chat-footer {
    padding: 20px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .chat-footer--dark {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .chat-content {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 20px;
    background-color: #fff;
  }

  .chat-content--dark {
    background-color: #222;
  }

  .chat-message-wrapper {
    display: flex;
    gap: 4px;
    margin-bottom: 24px
  }

  .chat-message-wrapper-user {
    justify-content: flex-end;
    gap: 12px;
  }

  .chat-message-wrapper img {
    width: 40px;
    border-radius: 50%;
    height: 40px;
    object-fit: cover;
    object-position: center;
  }
  
  .chat-message {
    background: #fff;
    padding: 12px;
    border-radius: 24px;
    max-width: 90%;
    background: rgba(0, 0, 0, 0.04);
    line-height: 1.5;
    margin: 20px 0 0 8px;
  }

  .chat-message--dark {
    background-color: #505050;
  }

  .bot-message {
    background: #F5F5F5;
    float: left;
    clear: both;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.04);
    z-index: 10
  }

  .bot-message-before {
    content: '';
    position: absolute;
    left: -8px;
    top: 0;
    width: 20px;
    height: 20px;
    background: #F5F5F5;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotateZ(-50deg);
  }

  .bot-message-before--dark {
    background-color: #505050;
  }

  .bot-message--dark {
    background: #505050;
    border: 1px solid rgba(255, 255, 255, 0.04);
  }

  .bot-message a:hover {
    color: #FDC112;
  }
  
  .user-message {
    color: white;
    float: right;
    clear: both;
    position: relative;
  }

  .user-message-before {
    position: absolute;
    right: -8px;
    top: 0;
    width: 20px;
    height: 20px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotateZ(50deg);
  }

  .chat-message-reference {
    display: flex;
    gap: 14px
  }
  
  .chat-input {
    flex: 1;
    border: none;
    margin-right: 10px;
    resize: none;
    height: auto;
    max-height: calc(2 * 1.4em);
    overflow-y: auto;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    outline: 0;
    background: #fff;
    padding: 10px 0;
  }

  .chat-input--dark {
    background: #222;
  }
  
  .send-button {
    color: white;
    border-radius: 50%;
    cursor: pointer;
    height: 44px;
    width: 44px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.4s linear;
  }

  .send-button:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
    transition: background-color 0.4s linear;
  }  
  
  .send-button:hover:enabled {
    background-color: #e0a800;
    transition: background-color 0.4s linear;
  }

  .file-tag {
    display: inline-block;
  }
  
  .file-tag-link {
    display: flex;
    gap: 12px;
    margin-top: 14px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-radius: 16px;
    align-items: center;
    color: #777;
  }